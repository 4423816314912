<template>
  <div class="about-page">

    <!-- Basic info: -->
    <h1>About</h1>
    <hr/>

    <p>I am 32 year-old Software Engineer living in Vancouver.</p>

    <hr/>
    <router-link to="/" class="btn btn-outline-dark">Go Back</router-link>

  </div>

</template>

<script>
export default {
  name: 'PortfolioView',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.about-page {
  width: 680px;
  max-width: 100%;
  margin: 0 auto;
  padding: 5em 2em;
  text-align: center;
}

hr {
  margin: 1.75em 0;
}

</style>
