<template>
  <div class="home-page">

    <!-- Basic info: -->
    <h1> Joaquin Paniagua</h1>
    <hr />

    <!-- Main links: -->
    <ul class="main-links">
      <li><router-link to="/about" class="btn btn-outline-dark">About</router-link></li>
      <li><router-link to="/portfolio" class="btn btn-outline-dark">Portfolio</router-link></li>
      <li><a href="https://blog.chatopaniagua.com" class="btn btn-outline-dark">Blog</a></li>
      <li><a href="https://store.chatopaniagua.com" class="btn btn-outline-dark">Store</a></li>
    </ul>
    
    <hr />

    <!-- Social links: -->
    <ul class="social-links">
      <li><a href="https://www.instagram.com/chatopaniagua/"><i class="fa-brands fa-instagram"></i></a></li>
      <li><a href="https://www.twitter.com/joaquinpgl/"><i class="fa-brands fa-twitter"></i></a></li>
      <li><a href="https://www.facebook.com/joaquinpaniagua/"><i class="fa-brands fa-facebook"></i></a></li>
      <li><a href="https://www.linkedin.com/in/joaquinpan/"><i class="fa-brands fa-linkedin"></i></a></li>
      <li><a href="mailto:chatopaniagua@gmail.com"><i class="fa-regular fa-envelope"></i></a></li>
    </ul>
  
  </div>

</template>

<script>
export default {
  name: 'HomePage',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home-page {
  width: 480px;
  max-width: 100%;
  margin: 0 auto;
  padding: 5em 2em;
  box-sizing: border-box;
  text-align: center;
}

hr {
  margin: 1.75em 0;
}

.main-links {
  li {
    &:not(:first-child) {
      margin-top: 1em;
    }
    a {
      padding: 0.5em;
      display: block;
    }
  }
}

a {
  color: black;
}

.social-links {
  text-align: center;
  font-size: 20px;
}

.social-links li {
  display: inline-block;
  margin: 0 0.5em;
}

.social-links i.fa-instagram:hover { color: red; }
.social-links i.fa-twitter:hover { color: #009dff; }
.social-links i.fa-facebook:hover { color: blue; }
.social-links i.fa-linkedin:hover { color: #006280; }

</style>